import React from 'react';

export default function BaoParty({ themeColor, ...rest }) {
  return (
    <svg
      viewBox="0 0 214 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_106_30)">
        <path
          d="M202.48 131.36C203.659 130.493 204.922 129.746 206.25 129.13C207.496 128.531 208.916 128.399 210.25 128.76C210.928 129.024 211.542 129.429 212.05 129.95C212.6 130.417 213.023 131.016 213.28 131.69C213.83 133.4 212.72 135.49 211.44 137.15C209.465 139.709 207.026 141.873 204.25 143.53C203.78 143.853 203.237 144.056 202.67 144.12C202.197 144.104 201.738 143.951 201.35 143.68C199.089 142.389 197.042 140.757 195.28 138.84C193.85 137.27 195.09 136.46 196.69 135.37C198.65 134.06 200.59 132.7 202.48 131.36Z"
          fill={themeColor}
        />
        <path
          d="M46.0501 82.21C35.3301 88.21 23.9599 93.84 16.1699 103.34C12.5851 107.983 9.56402 113.035 7.16995 118.39C4.93339 122.798 3.09283 127.396 1.66995 132.13C-4.20005 153.21 6.07 177.66 25.22 188.23C31.22 191.52 38.4101 194.15 41.0401 200.42C43.2601 205.7 41.4001 212.03 44.6001 217.11C45.1186 217.949 45.7984 218.676 46.6001 219.25C51.4701 222.71 56.9999 218.46 59.1199 213.68C61.2399 208.9 61.36 203.6 64.18 199.24C64.5039 198.72 64.9327 198.274 65.439 197.93C65.9452 197.585 66.5177 197.35 67.1199 197.24C70.2699 196.74 71.9199 200.3 72.6699 203.24C73.9799 208.33 75.32 213.78 80 216.8C81.2116 217.637 82.6404 218.103 84.1123 218.142C85.5843 218.181 87.0358 217.791 88.2901 217.02C94.4601 212.9 88.6401 201.7 94.8001 199.02C98.5801 197.36 102.4 201.41 103.9 205.25C105.4 209.09 106.5 213.74 110.25 215.45C114 217.16 119.58 218.15 122.44 215.79C127.8 211.36 127.73 203.37 130.8 197.36C133.8 194.36 142.68 194.36 143.8 199.36C144.37 201.87 141.48 207.84 142.07 210.36C143.21 215.18 148.57 218.07 153.52 217.89C158.47 217.71 162.99 215.14 167.05 212.32C172.52 208.5 177.82 203.68 179.58 197.25C180.1 195.36 180.33 193.25 181.58 191.78C182.65 190.49 184.28 189.84 185.77 189.08C194.97 184.39 200.3 174.33 202.69 164.28C207.11 145.67 203.33 126.17 198.77 107.59C195.52 94.3201 191.5 80.5 181.88 70.79C174.59 63.44 164.82 59.2 155.26 55.23C134.8 46.7367 114.167 38.6867 93.3599 31.08C88.5299 29.31 80.2899 27.25 76.8599 32.57C72.1699 39.85 82.86 44.5 82.26 51.57C81.07 64.34 55.8401 76.75 46.0501 82.21Z"
          fill={themeColor}
        />
        <path
          d="M142.1 99.54C141.51 99.54 140.81 99.54 140.51 100.1C140.413 100.331 140.371 100.581 140.387 100.83C140.403 101.08 140.476 101.323 140.6 101.54C140.933 102.482 141.461 103.342 142.15 104.064C142.84 104.786 143.675 105.354 144.6 105.73C146.49 106.36 148.92 105.52 149.54 103.63C149.663 103.218 149.75 102.797 149.8 102.37C150.18 100.22 150.61 99.64 148.13 99.56C146.12 99.49 144.1 99.55 142.1 99.54Z"
          fill="#726D68"
        />
        <path
          opacity="0.25"
          d="M108.487 120.856C120.065 120.178 129.033 112.478 128.516 103.656C128 94.8349 118.195 88.2331 106.617 88.9109C95.039 89.5886 86.0717 97.2892 86.588 106.111C87.1044 114.932 96.909 121.534 108.487 120.856Z"
          fill="#FCE9B4"
        />
        <path
          opacity="0.25"
          d="M196.129 103.738C197.36 95.6131 189.715 87.7171 179.053 86.1017C168.391 84.4863 158.75 89.7631 157.519 97.888C156.288 106.013 163.933 113.909 174.595 115.524C185.257 117.14 194.898 111.863 196.129 103.738Z"
          fill="#FCE9B4"
        />
        <path
          d="M123.83 48.06L144.23 15.6C144.305 15.4859 144.412 15.3956 144.537 15.3398C144.662 15.284 144.8 15.265 144.935 15.285C145.071 15.3049 145.198 15.363 145.301 15.4525C145.405 15.5419 145.481 15.6589 145.52 15.79L155.91 52.69L156.41 54.78C155.97 57.78 148.09 59.14 138.8 57.78C129.51 56.42 122.32 52.91 122.8 49.9L123.83 48.06Z"
          fill="#D4145A"
        />
        <path
          d="M130.51 49.23L129.05 47.88L127.11 48.32L127.94 46.51L126.92 44.8L128.89 45.03L130.21 43.53L130.6 45.48L132.43 46.27L130.69 47.24L130.51 49.23Z"
          fill="#FFF171"
        />
        <path
          d="M141.05 39.93L140.04 38.21L138.05 38.07L139.37 36.58L138.89 34.65L140.71 35.44L142.4 34.39L142.21 36.37L143.74 37.65L141.79 38.08L141.05 39.93Z"
          fill="#FFF171"
        />
        <path
          d="M140.22 30.7C141.048 30.7 141.72 30.0284 141.72 29.2C141.72 28.3716 141.048 27.7 140.22 27.7C139.392 27.7 138.72 28.3716 138.72 29.2C138.72 30.0284 139.392 30.7 140.22 30.7Z"
          fill="#FFF171"
        />
        <path
          d="M149.4 37.07C150.228 37.07 150.9 36.3984 150.9 35.57C150.9 34.7416 150.228 34.07 149.4 34.07C148.572 34.07 147.9 34.7416 147.9 35.57C147.9 36.3984 148.572 37.07 149.4 37.07Z"
          fill="#FFF171"
        />
        <path
          d="M132.85 39.74C133.678 39.74 134.35 39.0684 134.35 38.24C134.35 37.4116 133.678 36.74 132.85 36.74C132.022 36.74 131.35 37.4116 131.35 38.24C131.35 39.0684 132.022 39.74 132.85 39.74Z"
          fill="#FFF171"
        />
        <path
          d="M137.5 49.5C138.328 49.5 139 48.8284 139 48C139 47.1716 138.328 46.5 137.5 46.5C136.672 46.5 136 47.1716 136 48C136 48.8284 136.672 49.5 137.5 49.5Z"
          fill="#FFF171"
        />
        <path
          d="M145.29 44.56C146.118 44.56 146.79 43.8884 146.79 43.06C146.79 42.2316 146.118 41.56 145.29 41.56C144.462 41.56 143.79 42.2316 143.79 43.06C143.79 43.8884 144.462 44.56 145.29 44.56Z"
          fill="#FFF171"
        />
        <path
          d="M147.52 54.46L145.75 53.55L143.99 54.49L144.31 52.53L142.88 51.15L144.84 50.85L145.71 49.05L146.61 50.83L148.58 51.1L147.16 52.5L147.52 54.46Z"
          fill="#FFF171"
        />
        <path
          d="M139.26 26.26L140.67 24.86L142.63 25.23L141.73 23.45L142.69 21.71L140.72 22.01L140.41 21.67L138.82 24.19L139.01 24.29L139.26 26.26Z"
          fill="#FFF171"
        />
        <path
          d="M151.72 43.6L152.22 45.53L150.92 47.04L152.91 47.15L153.94 48.85L154.46 47.54L153.56 44.37V44.38L151.72 43.6Z"
          fill="#FFF171"
        />
        <path
          d="M145.26 18.81C145.232 19.005 145.242 19.2037 145.29 19.3947C145.339 19.5857 145.425 19.7653 145.543 19.9232C145.661 20.081 145.809 20.2141 145.978 20.3148C146.147 20.4155 146.335 20.4818 146.53 20.51C146.636 20.5248 146.744 20.5248 146.85 20.51L146.06 17.69C145.845 17.7965 145.659 17.9545 145.52 18.1498C145.38 18.3452 145.291 18.5719 145.26 18.81Z"
          fill="#FFF171"
        />
        <path
          d="M145.62 26.44L146.6 28.18L145.72 29.96L147.68 29.57L149.11 30.95L149.3 29.24L148.36 25.88L147.59 26.72L145.62 26.44Z"
          fill="#FFF171"
        />
        <path
          d="M155.48 51.29C155.168 51.4049 154.903 51.6203 154.727 51.9022C154.55 52.184 154.473 52.5165 154.506 52.8473C154.539 53.1781 154.681 53.4885 154.91 53.7297C155.139 53.971 155.441 54.1293 155.77 54.18C155.918 54.2102 156.071 54.2102 156.22 54.18L155.87 52.69L155.48 51.29Z"
          fill="#FFF171"
        />
        <path
          d="M148.22 89.73C148.296 88.7082 148.557 87.7087 148.99 86.78C149.21 86.3171 149.527 85.9065 149.918 85.5755C150.31 85.2444 150.767 85.0005 151.26 84.86C151.898 84.7592 152.552 84.8261 153.157 85.054C153.762 85.2819 154.297 85.663 154.71 86.16C155.526 87.1573 156.16 88.2916 156.58 89.51"
          stroke="#FFF5E9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.54 88.83C130.625 88.0277 130.92 87.2621 131.396 86.6106C131.872 85.9591 132.512 85.4449 133.25 85.12C133.944 84.7977 134.728 84.7236 135.47 84.91C136.348 85.1995 137.082 85.8152 137.52 86.63C137.96 87.4413 138.296 88.3048 138.52 89.2"
          stroke="#FFF5E9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.36"
          d="M97.3899 141.06C97.1036 141.139 96.8036 141.152 96.5113 141.101C96.2189 141.049 95.9417 140.932 95.7 140.76C90.7 137.2 85.6201 133.49 82.1001 128.46C80.6901 126.46 79.54 123.79 80.69 121.63C80.8568 121.284 81.1302 121 81.47 120.82C81.7718 120.716 82.0931 120.682 82.4099 120.72C83.654 120.803 84.8765 121.087 86.0301 121.56C87.9058 122.434 89.6548 123.558 91.23 124.9L100.68 132.26C100.953 132.464 101.167 132.736 101.3 133.05C101.679 133.988 101.818 135.005 101.706 136.011C101.595 137.016 101.235 137.978 100.66 138.81C99.8568 139.909 98.7036 140.703 97.3899 141.06Z"
          fill="white"
        />
        <path
          d="M198.94 46.39L199.86 52.33L205.27 54.95L199.9 57.65L199.08 63.6L194.85 59.33L188.94 60.4L191.69 55.05L188.85 49.76L194.78 50.73L198.94 46.39Z"
          fill="#FFF171"
        />
        <path
          d="M53.5099 35.17L57.1099 39.99L63.1199 39.76L59.6499 44.66L61.7299 50.3L55.9899 48.52L51.2699 52.24L51.1899 46.23L46.1899 42.89L51.8799 40.96L53.5099 35.17Z"
          fill="#FFF171"
        />
        <path
          d="M118.17 0L121.37 5.09L127.37 5.35L123.52 9.96L125.13 15.75L119.55 13.51L114.54 16.83L114.95 10.83L110.24 7.1L116.07 5.63L118.17 0Z"
          fill="#FFF171"
        />
        <path
          d="M83.68 14L85.49 16.32L88.27 17.31L85.94 19.12L84.96 21.89L83.15 19.57L80.37 18.59L82.69 16.78L83.68 14Z"
          fill="#EA63A1"
        />
        <path
          d="M165.85 28.24L166.58 31.09L168.74 33.1L165.89 33.83L163.88 35.99L163.15 33.13L160.99 31.13L163.84 30.39L165.85 28.24Z"
          fill="#EA63A1"
        />
      </g>
      <defs>
        <clipPath id="clip0_106_30">
          <rect width="213.44" height="220.51" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
