import React from 'react';

export default function InstanceLogo({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 282.89 222.28"
    >
      <path d="M229.51,99.23,161.86,31.57a16.85,16.85,0,0,0-23.83,0L70.38,99.23a16.84,16.84,0,0,0,0,23.82L138,190.7a16.85,16.85,0,0,0,23.83,0l44.39-44.39a19.33,19.33,0,0,0,1.16-10.24,18.66,18.66,0,0,0-4.26-8.94,20.84,20.84,0,0,0-9-5.48,21.64,21.64,0,0,0-20.65,5.3c-3.29,3-5.89,5.73-8.41,8.35-1,1-2,2.07-3,3.11a6.35,6.35,0,0,1-9,.07l0,0a6.34,6.34,0,0,1,0-8.9l1.8-1.87c2.64-2.74,5.63-5.84,9.62-9.56,14.51-13.54,34.63-13.33,47.83.44l.1.1a34.3,34.3,0,0,1,7.4,14.08l9.68-9.68A16.84,16.84,0,0,0,229.51,99.23Zm-62.75-3c-.88.91-1.29,1.36-1.66,1.76-.6.66-1.07,1.17-3.07,3.13l-.19.16c-4.12,4.11-10.8,10.78-16.84,16.79s-8.67,13.08-7.56,19.76a18.77,18.77,0,0,0,4.27,8.94,20.77,20.77,0,0,0,8.94,5.48A21.63,21.63,0,0,0,171.31,147c3.29-3,5.89-5.73,8.41-8.34,1-1,2-2.07,3-3.11a6.34,6.34,0,0,1,9-.07l0,0a6.36,6.36,0,0,1,.05,8.9L190,146.27c-2.63,2.74-5.62,5.84-9.6,9.55-14.31,13.35-34.05,13.35-47.27.14l-.57-.59-.1-.09A33.62,33.62,0,0,1,124.55,127a31.36,31.36,0,0,1,1.17-4.26,1.09,1.09,0,0,1,0-.17s1.57-4.63-.1-8.65l0-.07c-10.78-20.91-5.06-26.63,6.69-38.39,5-5.07,12-12.19,13.85-14a6.34,6.34,0,0,1,9-.07l0,0a6.37,6.37,0,0,1,0,8.91c-1.95,2-12.23,12.42-17.72,17.89-7.22,7.23-3,16.51-.74,20.29,7.35-7.38,18.89-19,20.94-21.13a6.34,6.34,0,0,1,9,8.87Z" />
    </svg>
  );
}
