import React from 'react';

export default function Bao({ themeColor, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 223.48 191.45"
      {...rest}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M181.66,101.5a73.83,73.83,0,0,0,14.39-5.65c5.15-2.68,7.73-4,9.78-6.16C209.36,86,210.69,82,214,81.88a3.66,3.66,0,0,1,2.64.84c1.53,1.46.86,4.41.59,5.58A13.89,13.89,0,0,1,214.36,94c-9.09,10.78-26.77,16.35-29.64,16a12.58,12.58,0,0,1-1.79-.39,15.51,15.51,0,0,1-2.57-.92c-2.05-1-2.88-3.91-3.12-4.86a3.85,3.85,0,0,1,.92-1.33A4.34,4.34,0,0,1,181.66,101.5Z"
            fill={themeColor}
          />
          <path
            d="M217.77,76.88a4.87,4.87,0,0,1,5.33,4.71"
            fill="none"
            stroke="#444"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <path
            d="M211.49,72.59a11.69,11.69,0,0,1,11.13.17"
            fill="none"
            stroke="#444"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <path
            d="M46.05,53.15c-10.73,6-22.1,11.63-29.88,21.12a74.48,74.48,0,0,0-9,15.06,82.6,82.6,0,0,0-5.5,13.74c-5.87,21.07,4.4,45.53,23.55,56.1,6,3.29,13.18,5.92,15.82,12.19,2.22,5.28.36,11.61,3.55,16.69a7.32,7.32,0,0,0,2,2.13c4.86,3.47,10.39-.78,12.51-5.56s2.25-10.08,5.07-14.44a4.38,4.38,0,0,1,2.94-2c3.15-.5,4.79,3.06,5.54,6,1.32,5.08,2.66,10.54,7.34,13.56a7.59,7.59,0,0,0,8.28.22c6.18-4.13.36-15.32,6.52-18,3.77-1.66,7.6,2.39,9.1,6.23s2.59,8.49,6.34,10.2,9.33,2.7,12.19.33c5.37-4.43,5.29-12.41,8.37-18.43,3-3,11.88-3,13,2,.56,2.52-2.32,8.49-1.73,11,1.14,4.82,6.5,7.71,11.44,7.52s9.48-2.74,13.54-5.57c5.47-3.81,10.76-8.63,12.52-15.07.52-1.88.76-4,2-5.46a12.39,12.39,0,0,1,4.18-2.7c9.21-4.69,14.54-14.75,16.93-24.81,4.41-18.6.64-38.1-3.92-56.68-3.26-13.28-7.27-27.09-16.89-36.8-7.29-7.35-17.07-11.59-26.62-15.56Q124.52,13.32,93.31,1.91C88.48.15,80.24-1.91,76.81,3.41c-4.69,7.28,6,11.92,5.39,19C81.06,35.28,55.84,47.69,46.05,53.15Z"
            fill={themeColor}
          />
          <path
            d="M122.44,44.75a1.11,1.11,0,0,1,.33-.63c.55-.43,1.51,0,2.07.5,3.47,3,4.56,7.2,4.95,11.16a13.28,13.28,0,0,1-.77,7c-.71,1.49-3.12,4.53-5.64,4-2.75-.6-2.28-4.28-2.42-6A46.7,46.7,0,0,1,122.44,44.75Z"
            fill="#fff5e9"
          />
          <path
            d="M150.62,45.47a1.24,1.24,0,0,0-.28-.64c-.46-.45-1.22,0-1.67.43-2.74,2.83-3.53,7-3.76,10.94a16.56,16.56,0,0,0,.78,7c.6,1.51,2.61,4.62,4.64,4.15,2.21-.5,1.76-4.18,1.83-5.92A57.41,57.41,0,0,0,150.62,45.47Z"
            fill="#fff5e9"
          />
          <path
            d="M135.1,73.48c-.59,0-1.29,0-1.59.56a1.63,1.63,0,0,0,.08,1.44,7.09,7.09,0,0,0,4,4.19c1.89.63,4.32-.21,4.95-2.1a7.83,7.83,0,0,0,.26-1.26c.37-2.15.8-2.73-1.67-2.81C139.11,73.43,137.1,73.48,135.1,73.48Z"
            fill="#726d68"
          />
          <ellipse
            cx="97.52"
            cy="77"
            rx="21"
            ry="16"
            transform="translate(-4.33 5.82) rotate(-3.35)"
            fill="#fce9b4"
            opacity="0.25"
          />
          <ellipse
            cx="174.02"
            cy="76.85"
            rx="14.86"
            ry="19.5"
            transform="matrix(0.15, -0.99, 0.99, 0.15, 71.93, 237.38)"
            fill="#fce9b4"
            opacity="0.25"
          />
        </g>
      </g>
    </svg>
  );
}
