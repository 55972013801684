import React from 'react';

export default function BaoDetective({ themeColor, ...rest }) {
  return (
    <svg
      viewBox="0 0 250 192"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_7_7)">
        <path
          d="M210.34 112.32C211.54 112.59 212.75 112.86 213.85 113.41C214.95 113.96 215.94 114.82 216.4 115.96C216.67 116.63 216.74 117.36 216.75 118.08C216.77 118.79 216.73 119.52 216.45 120.17C215.77 121.77 213.89 122.46 212.17 122.73C209.52 123.14 206.78 122.94 204.22 122.14C203.75 121.99 203.27 121.82 202.95 121.45C202.65 121.11 202.53 120.64 202.43 120.2C201.87 117.67 201.63 115.07 201.73 112.48C201.81 110.36 203.01 110.67 204.58 111.03C206.5 111.47 208.42 111.89 210.34 112.32Z"
          fill={themeColor}
        />
        <path
          d="M46.05 53.15C35.33 59.13 23.96 64.78 16.17 74.28C12.45 78.81 9.70001 84.06 7.15001 89.34C5.00001 93.79 2.97001 98.32 1.65001 103.08C-4.20999 124.16 6.05001 148.61 25.2 159.18C31.16 162.47 38.38 165.1 41.02 171.37C43.24 176.65 41.38 182.98 44.57 188.06C45.09 188.89 45.75 189.63 46.55 190.19C51.42 193.66 56.94 189.4 59.06 184.62C61.13 179.94 61.31 174.54 64.13 170.18C64.8 169.14 65.85 168.35 67.07 168.16C70.21 167.67 71.86 171.22 72.61 174.12C73.93 179.21 75.26 184.66 79.94 187.68C82.46 189.3 85.73 189.56 88.22 187.9C94.4 183.78 88.58 172.58 94.74 169.87C98.51 168.21 102.34 172.26 103.84 176.1C105.34 179.94 106.44 184.59 110.19 186.3C113.93 188 119.52 188.99 122.38 186.63C127.75 182.2 127.67 174.22 130.75 168.2C133.75 165.2 142.63 165.19 143.75 170.2C144.31 172.72 141.43 178.68 142.02 181.2C143.16 186.02 148.52 188.91 153.46 188.72C158.4 188.53 162.93 185.98 166.99 183.15C172.46 179.34 177.76 174.51 179.52 168.08C180.04 166.19 180.27 164.12 181.52 162.62C182.59 161.33 184.21 160.68 185.71 159.92C194.92 155.22 200.24 145.17 202.63 135.11C207.05 116.5 203.27 97 198.72 78.43C195.46 65.15 191.45 51.34 181.82 41.63C174.53 34.28 164.76 30.04 155.2 26.07C134.74 17.57 114.11 9.52001 93.3 1.91001C88.47 0.140007 80.23 -1.91999 76.8 3.41001C72.11 10.69 82.81 15.33 82.19 22.36C81.06 35.28 55.84 47.69 46.05 53.15Z"
          fill={themeColor}
        />
        <path
          opacity="0.25"
          d="M108.457 91.9709C120.035 91.2936 129.003 83.5933 128.487 74.7717C127.971 65.9502 118.166 59.348 106.588 60.0253C95.0098 60.7027 86.0422 68.403 86.5582 77.2245C87.0743 86.0461 96.8786 92.6483 108.457 91.9709Z"
          fill="#FCE9B4"
        />
        <path
          opacity="0.25"
          d="M195.299 78.7764C196.53 70.6622 188.896 62.7748 178.249 61.1593C167.601 59.5439 157.971 64.8122 156.74 72.9264C155.509 81.0406 163.143 88.9281 173.791 90.5435C184.438 92.1589 194.068 86.8906 195.299 78.7764Z"
          fill="#FCE9B4"
        />
        <path
          d="M220.67 97.12L211.8 123.68"
          stroke="#444444"
          strokeWidth="7"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M227.72 96.6C239.125 96.6 248.37 87.3547 248.37 75.95C248.37 64.5453 239.125 55.3 227.72 55.3C216.315 55.3 207.07 64.5453 207.07 75.95C207.07 87.3547 216.315 96.6 227.72 96.6Z"
          fill="#E8E8E8"
          stroke="#444444"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.67 60.66C233.11 58.86 240.61 63.43 242.41 70.87"
          stroke="#444444"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M156.83 57.18C158.02 57.28 159.33 57.47 160.07 58.86C160.13 58.96 160.18 59.08 160.2 59.22C160.26 59.64 160 60.06 159.76 60.34C157.37 63.09 154.29 63.11 151.65 62.41C151.23 62.3 150.83 62.03 150.54 61.56C149.38 59.73 150.52 56.92 151.93 56.78C153.5 56.63 155.26 57.05 156.83 57.18Z"
          fill="#FFF5E9"
        />
        <path
          d="M134.07 57.1C132.7 57.14 131.2 57.27 130.25 58.62C130.18 58.72 130.11 58.83 130.08 58.97C129.98 59.39 130.24 59.82 130.5 60.11C133.03 62.97 136.53 63.15 139.6 62.57C140.09 62.48 140.56 62.23 140.93 61.78C142.38 60.01 141.3 57.14 139.7 56.94C137.91 56.71 135.87 57.04 134.07 57.1Z"
          fill="#FFF5E9"
        />
        <path
          opacity="0.5"
          d="M130.52 52.24C133.6 45.3 138.23 46.84 140.54 52.45"
          stroke="#FCE9B4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M148.11 68.38L154.11 71.27"
          stroke="#726D68"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M153.55 67.49L148.68 72.17"
          stroke="#726D68"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.36"
          d="M111.89 102.1C117.25 100.62 121.49 98.72 124.52 97.14C129.05 94.79 131.31 93.61 133.11 91.73C136.21 88.49 137.38 85.01 140.29 84.87C140.62 84.85 141.8 84.84 142.6 85.61C143.95 86.89 143.35 89.48 143.12 90.51C142.49 93.24 140.99 95.07 140.6 95.52C132.62 104.99 117.1 109.88 114.57 109.54C113.9 109.45 113.4 109.3 113 109.2C112.08 108.96 111.17 108.61 110.74 108.39C108.94 107.48 108.21 104.96 108 104.12C108.14 103.79 108.37 103.35 108.8 102.95C110 101.84 111.62 102.06 111.89 102.1Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_7">
          <rect width="249.88" height="191.45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
