import React from 'react';

export default function BaoSpills({ themeColor, ...rest }) {
  return (
    <svg
      viewBox="0 0 317 193"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_72_156)">
        <path
          d="M195.46 111.92C196.976 113.732 198.222 115.752 199.16 117.92C199.99 120.13 200.16 122.45 200.88 124.68C201.6 126.91 203.41 129.22 205.51 129.39C207.37 129.54 208.73 127.88 209.03 125.98C209.18 123.95 208.925 121.911 208.28 119.98C207.14 115.56 205.91 110.98 203.21 107.17L195.46 111.92Z"
          fill={themeColor}
        />
        <path
          d="M46.05 53.15C35.32 59.15 23.95 64.78 16.17 74.27C12.5872 78.9179 9.56622 83.973 7.17001 89.33C4.92923 93.7362 3.08851 98.3347 1.67001 103.07C-4.19999 124.14 6.07001 148.6 25.22 159.17C31.22 162.46 38.4 165.09 41.04 171.36C43.26 176.64 41.4 182.97 44.59 188.05C45.1068 188.887 45.787 189.612 46.59 190.18C51.45 193.65 56.98 189.4 59.1 184.62C61.22 179.84 61.35 174.54 64.17 170.18C64.493 169.66 64.9215 169.213 65.4279 168.868C65.9343 168.524 66.5074 168.289 67.11 168.18C70.26 167.68 71.9 171.24 72.65 174.18C73.97 179.26 75.31 184.72 79.99 187.74C81.2005 188.575 82.6274 189.04 84.0974 189.079C85.5673 189.118 87.0169 188.729 88.27 187.96C94.45 183.83 88.63 172.64 94.79 169.96C98.56 168.3 102.39 172.35 103.89 176.19C105.39 180.03 106.48 184.68 110.23 186.39C113.98 188.1 119.56 189.09 122.42 186.72C127.79 182.29 127.71 174.31 130.79 168.29C133.79 165.29 142.67 165.29 143.79 170.29C144.35 172.81 141.47 178.78 142.06 181.29C143.2 186.11 148.56 189 153.5 188.81C158.44 188.62 162.98 186.07 167.04 183.24C172.51 179.43 177.8 174.61 179.56 168.17C180.08 166.29 180.32 164.17 181.56 162.71C182.756 161.537 184.178 160.618 185.74 160.01C194.95 155.32 200.28 145.26 202.67 135.2C207.08 116.6 203.31 97.1 198.75 78.52C195.49 65.24 191.48 51.43 181.86 41.72C174.57 34.37 164.79 30.13 155.24 26.16C134.76 17.6 114.117 9.51668 93.31 1.91001C88.48 0.150011 80.24 -1.90999 76.81 3.41001C72.12 10.69 82.81 15.33 82.2 22.41C81.06 35.28 55.84 47.69 46.05 53.15Z"
          fill={themeColor}
        />
        <path
          d="M128.46 51.06C130.77 54.38 130.95 56.85 130.61 58.79C130.41 59.96 130.02 61.04 128.83 61.37C127.9 61.5023 126.953 61.4275 126.056 61.1509C125.159 60.8743 124.334 60.4027 123.64 59.77C123.02 59.1913 122.561 58.4605 122.31 57.65C121.22 54.65 123.02 51.65 123.31 51.19C123.819 50.6197 124.5 50.2301 125.25 50.08C125.824 49.9934 126.411 50.0364 126.966 50.206C127.522 50.3756 128.032 50.6674 128.46 51.06Z"
          fill="#FFF5E9"
        />
        <path
          opacity="0.25"
          d="M100.456 91.9673C112.034 91.2896 121.002 83.589 120.485 74.7675C119.969 65.9461 110.164 59.3443 98.5861 60.022C87.0079 60.6997 78.0405 68.4003 78.5569 77.2218C79.0733 86.0433 88.8778 92.6451 100.456 91.9673Z"
          fill="#FCE9B4"
        />
        <path
          opacity="0.25"
          d="M189.303 78.7825C190.534 70.6685 182.901 62.7807 172.253 61.1647C161.605 59.5487 151.976 64.8164 150.744 72.9304C149.513 81.0445 157.146 88.9322 167.794 90.5482C178.441 92.1642 188.071 86.8965 189.303 78.7825Z"
          fill="#FCE9B4"
        />
        <path
          d="M133.47 65.33C134.194 66.4275 134.631 67.6895 134.74 69C134.774 69.6577 134.617 70.3112 134.286 70.8811C133.956 71.4509 133.468 71.9126 132.88 72.21"
          stroke="#726D68"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M142.93 64.84C142.516 66.0284 142.283 67.2723 142.24 68.53C142.24 69.84 142.59 71.24 143.24 71.73"
          stroke="#726D68"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M134.75 68.87H141.75"
          stroke="#726D68"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141 51.11C140.609 51.4585 140.316 51.9032 140.15 52.4C139.08 55.64 139.01 58.09 140.22 59.34C141.22 60.34 141.22 60.34 142.54 60.34C143.338 60.4522 144.151 60.3438 144.891 60.0264C145.632 59.7091 146.271 59.195 146.74 58.54C147.157 57.8717 147.395 57.1071 147.43 56.32C147.461 55.3575 147.294 54.3989 146.937 53.5041C146.581 52.6094 146.044 51.7978 145.36 51.12C145.081 50.8201 144.74 50.5846 144.36 50.43C143.912 50.2528 143.431 50.1777 142.95 50.21C142.218 50.285 141.532 50.6016 141 51.11Z"
          fill="#FFF5E9"
        />
        <path
          opacity="0.36"
          d="M92.46 104.56C92.2221 104.738 92.0269 104.967 91.8885 105.23C91.7501 105.493 91.6721 105.783 91.66 106.08C91.37 112.23 91.19 118.49 92.98 124.37C93.7 126.71 95.08 129.26 97.5 129.68C97.8718 129.77 98.2631 129.731 98.61 129.57C98.8813 129.4 99.1077 129.166 99.27 128.89C99.9704 127.858 100.5 126.72 100.84 125.52C101.301 123.501 101.492 121.429 101.41 119.36C101.41 115.36 101.41 111.36 101.41 107.36C101.428 107.01 101.345 106.663 101.17 106.36C100.763 105.618 100.201 104.973 99.5218 104.468C98.8428 103.963 98.0631 103.611 97.2357 103.434C96.4083 103.258 95.5525 103.262 94.7267 103.446C93.9009 103.629 93.1244 103.989 92.45 104.5L92.46 104.56Z"
          fill="white"
        />
        <path
          d="M265.17 173.34C269.45 172.64 274.04 172.34 277.83 174.47C279.62 175.47 280.17 176.28 281.98 177.24C284.93 178.8 288.42 179 291.71 179.62C292.13 179.7 292.85 179.82 293.82 179.92C294.94 180.026 296.066 180.053 297.19 180C298.06 180.972 298.981 181.896 299.95 182.77C304.12 186.5 306.95 187.29 306.87 188.3C306.77 189.12 298.57 191.07 288.87 189.69C284.87 189.07 287.36 189.29 283.37 188.85C280.49 188.53 277.49 188.27 274.74 189.2C273.57 189.6 273.46 190.71 272.27 191.07C271.33 191.35 269.39 190.98 268.41 191.01C266.54 191.06 264.41 189.71 262.74 190.6C260.74 191.69 258.74 191.19 256.85 191.14C256.444 189.887 255.688 188.776 254.671 187.94C253.654 187.103 252.418 186.576 251.11 186.42C250.888 187.184 250.505 187.893 249.988 188.498C249.471 189.103 248.83 189.591 248.11 189.93C246.657 190.597 245.114 191.049 243.53 191.27C241.267 191.817 238.932 192.009 236.61 191.84C235.39 191.69 233.91 190.95 234.05 189.73C234.125 189.379 234.297 189.057 234.546 188.798C234.796 188.54 235.112 188.357 235.46 188.27C236.156 188.108 236.866 188.011 237.58 187.98C239.553 187.729 241.349 186.713 242.58 185.15L236.89 183.72C237.13 181.84 239.17 180.8 240.98 180.24C242.79 179.68 244.87 179.11 245.71 177.41C245.913 176.773 246.21 176.17 246.59 175.62C246.67 175.522 246.77 175.443 246.885 175.39C246.999 175.336 247.124 175.309 247.25 175.31C248.256 175.331 249.249 175.075 250.12 174.57L255.48 170.67C256.58 169.89 259.17 171.87 260.48 171.87C261.48 171.87 264.12 170.71 264.48 170.87"
          fill="#B21807"
        />
        <path
          d="M313.78 189.69C315.31 189.69 316.55 188.45 316.55 186.92C316.55 185.39 315.31 184.15 313.78 184.15C312.25 184.15 311.01 185.39 311.01 186.92C311.01 188.45 312.25 189.69 313.78 189.69Z"
          fill="#42210B"
        />
        <path
          d="M247.85 175.48C247.01 175.593 246.239 176.004 245.676 176.637C245.113 177.271 244.796 178.085 244.783 178.933C244.77 179.78 245.061 180.604 245.603 181.255C246.146 181.906 246.904 182.34 247.74 182.48C248.632 182.492 249.521 182.576 250.4 182.73C251.24 183.02 251.89 184.16 251.26 184.8C251.09 184.97 250.85 185.08 250.73 185.29C250.43 185.77 250.95 186.34 251.45 186.62C252.666 187.249 254.051 187.472 255.403 187.256C256.754 187.041 258.001 186.397 258.96 185.42C259.749 184.329 260.601 183.284 261.51 182.29C262.217 181.762 263.03 181.393 263.893 181.206C264.755 181.02 265.648 181.021 266.51 181.21C268.22 181.595 269.866 182.221 271.4 183.07"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M264.69 170.74C264.446 171.115 264.316 171.553 264.316 172C264.316 172.447 264.446 172.885 264.69 173.26C264.956 173.502 265.282 173.667 265.634 173.739C265.986 173.812 266.351 173.788 266.69 173.67C267.376 173.415 268.044 173.114 268.69 172.77C270.597 172.019 272.713 171.991 274.64 172.69C276.544 173.396 278.354 174.333 280.03 175.48"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M264.7 185.4C264.868 186.111 265.224 186.763 265.731 187.288C266.238 187.813 266.877 188.192 267.581 188.384C268.286 188.577 269.029 188.576 269.732 188.381C270.436 188.187 271.074 187.807 271.58 187.28C272.58 186.28 273.2 184.61 274.58 184.46C275.228 184.457 275.865 184.622 276.43 184.94C276.995 185.257 277.646 185.386 278.29 185.31C278.92 185.17 279.46 184.46 279.18 183.88C278.966 183.543 278.635 183.297 278.25 183.19L273.36 181.14C272.738 180.944 272.172 180.601 271.71 180.14C271.487 179.901 271.354 179.591 271.334 179.265C271.314 178.938 271.408 178.615 271.6 178.35C272.16 177.74 273.16 178.01 273.92 178.35L281.37 181.35"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M235 183.71C237.133 183.771 239.257 184.008 241.35 184.42C241.61 184.444 241.862 184.522 242.091 184.647C242.319 184.773 242.52 184.944 242.68 185.15C242.826 185.493 242.832 185.879 242.698 186.226C242.563 186.574 242.299 186.855 241.96 187.01C241.271 187.299 240.527 187.435 239.78 187.41C238.017 187.507 236.288 187.931 234.68 188.66C234.11 188.91 233.48 189.36 233.56 189.97C233.64 190.58 234.23 190.81 234.75 190.97C237.353 191.82 240.103 192.13 242.83 191.88"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M245 188.44C244.915 188.367 244.815 188.314 244.707 188.286C244.599 188.257 244.486 188.253 244.377 188.274C244.267 188.295 244.164 188.341 244.074 188.408C243.985 188.475 243.911 188.561 243.86 188.66C243.777 188.874 243.764 189.109 243.821 189.331C243.878 189.554 244.004 189.753 244.18 189.9C244.539 190.184 244.974 190.355 245.43 190.39C246.539 190.526 247.663 190.287 248.62 189.71C248.96 189.535 249.233 189.254 249.4 188.91C249.73 188.06 248.89 187.2 248.05 186.84C247.4 186.57 246.7 186.42 246.05 186.14C245.716 186.019 245.41 185.83 245.152 185.586C244.894 185.342 244.689 185.047 244.55 184.72C244.425 184.39 244.435 184.023 244.577 183.7C244.719 183.376 244.982 183.122 245.31 182.99L238.91 182.31C238.37 182.25 237.64 181.95 237.78 181.42C237.87 181.08 238.28 180.96 238.62 180.89L242.4 180.11"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M259.39 187.61L255.87 188.61C255.858 189.295 256.093 189.962 256.532 190.489C256.971 191.016 257.584 191.367 258.26 191.48C259.613 191.658 260.988 191.493 262.26 191C263.533 190.511 264.906 190.339 266.26 190.5C267.19 190.767 268.099 191.101 268.98 191.5C269.422 191.703 269.908 191.796 270.394 191.77C270.88 191.743 271.352 191.599 271.77 191.35C272.43 190.86 272.7 189.99 273.33 189.45C274.217 188.873 275.285 188.645 276.33 188.81L290.28 189.33"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M285.65 183.9C288.243 185.209 291.24 185.47 294.02 184.63C294.67 184.43 295.4 184.04 295.47 183.37C295.54 182.7 294.71 182.05 293.95 181.85C290.72 181.03 287.1 182.85 284.04 181.53"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M281.19 186.42C283.53 185.83 285.98 186.5 288.28 187.25C290.28 187.91 292.52 188.64 294.5 187.86"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M280.38 177.4C281.025 177.24 281.696 177.209 282.353 177.309C283.01 177.409 283.641 177.637 284.21 177.98C285.346 178.672 286.566 179.216 287.84 179.6C289.36 179.77 290.898 179.709 292.4 179.42C293.154 179.277 293.93 179.298 294.675 179.481C295.42 179.664 296.118 180.004 296.72 180.48"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M251.72 174C252.101 173.353 252.607 172.79 253.211 172.343C253.814 171.896 254.5 171.576 255.23 171.4C255.963 171.238 256.727 171.285 257.434 171.536C258.141 171.787 258.764 172.232 259.23 172.82C259.668 173.426 259.874 174.17 259.809 174.915C259.745 175.66 259.415 176.358 258.88 176.88C257.49 178.09 255.34 177.6 253.7 176.78C253.221 176.5 252.696 176.307 252.15 176.21C251.876 176.168 251.595 176.213 251.348 176.339C251.1 176.464 250.898 176.664 250.77 176.91C250.712 177.13 250.7 177.359 250.732 177.584C250.765 177.809 250.842 178.025 250.96 178.22C251.338 178.986 251.874 179.664 252.532 180.208C253.191 180.752 253.957 181.152 254.78 181.379C255.603 181.607 256.466 181.658 257.31 181.528C258.155 181.399 258.962 181.093 259.68 180.63C260.97 179.8 261.9 178.5 263.15 177.63C264.113 176.954 265.231 176.532 266.4 176.403C267.57 176.274 268.753 176.441 269.84 176.89"
          stroke="#FBB03B"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M268.86 188.31C270.39 188.31 271.63 187.07 271.63 185.54C271.63 184.01 270.39 182.77 268.86 182.77C267.33 182.77 266.09 184.01 266.09 185.54C266.09 187.07 267.33 188.31 268.86 188.31Z"
          fill="#42210B"
        />
        <path
          d="M265.37 175.69C265.341 175.218 265.191 174.761 264.934 174.364C264.677 173.967 264.323 173.642 263.905 173.421C263.487 173.2 263.019 173.09 262.546 173.102C262.073 173.114 261.611 173.247 261.204 173.489C260.798 173.731 260.46 174.073 260.224 174.483C259.988 174.893 259.861 175.356 259.856 175.829C259.85 176.302 259.966 176.769 260.193 177.184C260.42 177.599 260.749 177.949 261.15 178.2C261.66 177.772 262.198 177.378 262.76 177.02C263.582 176.489 264.457 176.043 265.37 175.69Z"
          fill="#42210B"
        />
        <path
          d="M227.42 188.964C246.365 186.285 261.088 179.618 260.304 174.074C259.52 168.53 243.526 166.207 224.581 168.887C205.635 171.566 190.913 178.232 191.697 183.776C192.481 189.32 208.475 191.643 227.42 188.964Z"
          fill="#CCCCCC"
        />
        <path
          d="M225.385 183.051C236.875 181.477 245.817 177.477 245.357 174.118C244.897 170.758 235.209 169.31 223.718 170.884C212.227 172.458 203.285 176.458 203.746 179.818C204.206 183.177 213.894 184.625 225.385 183.051Z"
          fill="#E6E6E6"
        />
        <path
          d="M195 187L197 186.33L197.39 185H199"
          stroke="#999999"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M111 34.88L106.36 46.45C106.354 46.5066 106.354 46.5635 106.36 46.62C106.275 47.3447 106.345 48.0791 106.564 48.775C106.784 49.4709 107.148 50.1124 107.633 50.6575C108.118 51.2025 108.713 51.6387 109.378 51.9375C110.044 52.2362 110.765 52.3906 111.495 52.3906C112.225 52.3906 112.946 52.2362 113.612 51.9375C114.277 51.6387 114.872 51.2025 115.357 50.6575C115.842 50.1124 116.206 49.4709 116.426 48.775C116.645 48.0791 116.715 47.3447 116.63 46.62C116.635 46.5668 116.635 46.5133 116.63 46.46L111.99 34.89C111.991 34.7588 111.94 34.6323 111.849 34.5386C111.757 34.4448 111.631 34.3914 111.5 34.39C111.369 34.3887 111.242 34.4396 111.149 34.5315C111.055 34.6234 111.001 34.7488 111 34.88Z"
          fill="#A9F3FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_72_156">
          <rect width="316.55" height="192.73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
