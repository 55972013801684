const timeSpecifityChoices = [
  {
    label: 'I know the full date and time',
    value: 'time',
  },
  {
    label: 'I know the full date',
    value: 'day',
  },
  {
    label: 'I know the month and year',
    value: 'month',
  },
  {
    label: 'I know the year',
    value: 'year',
  },
];

export default timeSpecifityChoices;
